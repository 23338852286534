export const CategoryMapData: any = {
  1: {
    displayProperties: {
      description: '',
      name: 'Weapon',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Weapon',
    grantDestinyItemType: 3,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [2, 3, 4],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: true,
    hash: 1,
    index: 0,
    redacted: false,
    blacklisted: false,
  },
  2: {
    displayProperties: {
      description: 'Weapons that cause Kinetic damage.',
      name: 'Kinetic Weapon',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Kinetic',
    itemTypeRegex: 'type_weapon_(?!intrinsic).*',
    originBucketIdentifier: 'weapon_primary',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [1],
    groupCategoryOnly: false,
    hash: 2,
    index: 1,
    redacted: false,
    blacklisted: false,
  },
  3: {
    displayProperties: {
      description: 'Weapons that cause Energy damage (Arc, Solar, Void)',
      name: 'Energy Weapon',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Energy',
    itemTypeRegex: 'type_weapon_(?!intrinsic).*',
    originBucketIdentifier: 'weapon_special',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [1],
    groupCategoryOnly: false,
    hash: 3,
    index: 2,
    redacted: false,
    blacklisted: false,
  },
  4: {
    displayProperties: {
      description: 'Weapons that pack a hefty punch.',
      name: 'Power Weapon',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Power',
    itemTypeRegex: 'type_weapon_(?!intrinsic).*',
    originBucketIdentifier: 'weapon_heavy',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [1],
    groupCategoryOnly: false,
    hash: 4,
    index: 3,
    redacted: false,
    blacklisted: false,
  },
  5: {
    displayProperties: {
      description: '',
      name: 'Auto Rifle',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Auto Rifle',
    itemTypeRegex: '.*_auto_rifle',
    grantDestinyItemType: 0,
    grantDestinySubType: 6,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 5,
    index: 4,
    redacted: false,
    blacklisted: false,
  },
  6: {
    displayProperties: {
      description: '',
      name: 'Hand Cannon',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Hand Cannon',
    itemTypeRegex: '.*_hand_cannon',
    grantDestinyItemType: 0,
    grantDestinySubType: 9,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 6,
    index: 5,
    redacted: false,
    blacklisted: false,
  },
  7: {
    displayProperties: {
      description: '',
      name: 'Pulse Rifle',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Pulse Rifle',
    itemTypeRegex: '.*_pulse_rifle',
    grantDestinyItemType: 0,
    grantDestinySubType: 13,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 7,
    index: 6,
    redacted: false,
    blacklisted: false,
  },
  8: {
    displayProperties: {
      description: '',
      name: 'Scout Rifle',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Scout Rifle',
    itemTypeRegex: '.*_scout_rifle',
    grantDestinyItemType: 0,
    grantDestinySubType: 14,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 8,
    index: 7,
    redacted: false,
    blacklisted: false,
  },
  9: {
    displayProperties: {
      description: '',
      name: 'Fusion Rifle',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Fusion Rifle',
    itemTypeRegex: 'type_weapon_fusion_rifle',
    grantDestinyItemType: 0,
    grantDestinySubType: 11,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 9,
    index: 8,
    redacted: false,
    blacklisted: false,
  },
  10: {
    displayProperties: {
      description: '',
      name: 'Sniper Rifle',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Sniper Rifle',
    itemTypeRegex: '.*_sniper_rifle',
    grantDestinyItemType: 0,
    grantDestinySubType: 12,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 10,
    index: 9,
    redacted: false,
    blacklisted: false,
  },
  11: {
    displayProperties: {
      description: '',
      name: 'Shotgun',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Shotgun',
    itemTypeRegex: '.*_shotgun',
    grantDestinyItemType: 0,
    grantDestinySubType: 7,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 11,
    index: 10,
    redacted: false,
    blacklisted: false,
  },
  12: {
    displayProperties: {
      description: '',
      name: 'Machine Gun',
      hasIcon: false,
    },
    visible: false,
    deprecated: true,
    shortTitle: 'Machine Gun',
    itemTypeRegex: '.*_machinegun',
    grantDestinyItemType: 0,
    grantDestinySubType: 8,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 12,
    index: 11,
    redacted: false,
    blacklisted: false,
  },
  13: {
    displayProperties: {
      description: '',
      name: 'Rocket Launcher',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Rocket Launcher',
    itemTypeRegex: '.*_rocket_launcher',
    grantDestinyItemType: 0,
    grantDestinySubType: 10,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 13,
    index: 12,
    redacted: false,
    blacklisted: false,
  },
  14: {
    displayProperties: {
      description: '',
      name: 'Sidearm',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Sidearm',
    itemTypeRegex: '.*_sidearm',
    grantDestinyItemType: 0,
    grantDestinySubType: 17,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 14,
    index: 13,
    redacted: false,
    blacklisted: false,
  },
  16: {
    displayProperties: {
      description: '',
      name: 'Quest Step',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Quest Step',
    itemTypeRegex: '(type_step)|(type_chain_start)|(quest_type)|(type_quest)|(quest_global)',
    grantDestinyItemType: 12,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 16,
    index: 14,
    redacted: false,
    blacklisted: false,
  },
  18: {
    displayProperties: {
      description: 'Used to buy new weapons and gear.',
      name: 'Currencies',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Currencies',
    originBucketIdentifier: 'currency.*',
    grantDestinyItemType: 1,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 18,
    index: 16,
    redacted: false,
    blacklisted: false,
  },
  19: {
    displayProperties: {
      description: 'Icons and nameplates.',
      name: 'Emblems',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Emblems',
    itemTypeRegex: '.*emblem.*',
    grantDestinyItemType: 14,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 19,
    index: 17,
    redacted: false,
    blacklisted: false,
  },
  20: {
    displayProperties: {
      description: '',
      name: 'Armor',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Armor',
    grantDestinyItemType: 2,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [45, 46, 47, 48, 49],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: true,
    hash: 20,
    index: 18,
    redacted: false,
    blacklisted: false,
  },
  21: {
    displayProperties: {
      description: 'Warlock class helmets, chest, arms and legs',
      name: 'Warlock',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Warlock',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 2,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 21,
    index: 19,
    redacted: false,
    blacklisted: false,
  },
  22: {
    displayProperties: {
      description: 'Titan class helmets, chest, arms and legs',
      name: 'Titan',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Titan',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 0,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 22,
    index: 20,
    redacted: false,
    blacklisted: false,
  },
  23: {
    displayProperties: {
      description: 'Hunter class helmets, chest, arms and legs',
      name: 'Hunter',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Hunter',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 1,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 23,
    index: 21,
    redacted: false,
    blacklisted: false,
  },
  34: {
    displayProperties: {
      description: '',
      name: 'Engrams',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Engrams',
    itemTypeRegex: '(type_engram.*)|(type_silver_engram)',
    originBucketIdentifier: 'mission',
    grantDestinyItemType: 8,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 34,
    index: 27,
    redacted: false,
    blacklisted: false,
  },
  35: {
    displayProperties: {
      description: 'Ammo, upgrades and powerups.',
      name: 'Consumables',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Consumables',
    itemTypeRegex: 'type_consumable.*',
    grantDestinyItemType: 9,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [
      2253669532, 2088636411, 2150402250, 3747263590, 2266591099, 2250046497, 2005599723,
      3134521018,
    ],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 35,
    index: 28,
    redacted: false,
    blacklisted: false,
  },
  39: {
    displayProperties: {
      description: 'Cosmetic shells for your Ghost.',
      name: 'Ghost',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Ghost',
    itemTypeRegex: 'type_ghost.*',
    originBucketIdentifier: 'ghost',
    grantDestinyItemType: 24,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 39,
    index: 31,
    redacted: false,
    blacklisted: false,
  },
  40: {
    displayProperties: {
      description: 'Used to upgrade weapons and gear.',
      name: 'Materials',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Materials',
    itemTypeRegex: 'type_material.*',
    originBucketIdentifier: 'consumable',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 40,
    index: 32,
    redacted: false,
    blacklisted: false,
  },
  41: {
    displayProperties: {
      description: 'Add color and texture to existing armor.',
      name: 'Shaders',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Shaders',
    itemTypeRegex: 'type_shader.*',
    originBucketIdentifier: 'shader',
    grantDestinyItemType: 19,
    grantDestinySubType: 20,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 41,
    index: 33,
    redacted: false,
    blacklisted: false,
  },
  42: {
    displayProperties: {
      description: 'Spacecrafts used to traverse between destinations.',
      name: 'Ships',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Ships',
    itemTypeRegex: 'type_ship',
    originBucketIdentifier: 'ship',
    grantDestinyItemType: 21,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 42,
    index: 34,
    redacted: false,
    blacklisted: false,
  },
  43: {
    displayProperties: {
      description: 'Land vehicles used to quickly traverse a destination.',
      name: 'Sparrows',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Sparrows',
    itemTypeRegex: 'type_vehicle.*',
    originBucketIdentifier: 'vehicle',
    grantDestinyItemType: 22,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 43,
    index: 35,
    redacted: false,
    blacklisted: false,
  },
  44: {
    displayProperties: {
      description: 'Strike a pose.',
      name: 'Emotes',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Emotes',
    itemTypeRegex: 'type_emote.*',
    grantDestinyItemType: 23,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 44,
    index: 36,
    redacted: false,
    blacklisted: false,
  },
  45: {
    displayProperties: {
      description: '',
      name: 'Helmets',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Helmets',
    itemTypeRegex: 'type_armor.*',
    itemTypeRegexNot: 'type_armor_mask.*',
    originBucketIdentifier: 'head',
    grantDestinyItemType: 0,
    grantDestinySubType: 26,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [20],
    groupCategoryOnly: false,
    hash: 45,
    index: 37,
    redacted: false,
    blacklisted: false,
  },
  46: {
    displayProperties: {
      description: '',
      name: 'Arms',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Arms',
    itemTypeRegex: 'type_armor.*',
    originBucketIdentifier: 'arms',
    grantDestinyItemType: 0,
    grantDestinySubType: 27,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [20],
    groupCategoryOnly: false,
    hash: 46,
    index: 38,
    redacted: false,
    blacklisted: false,
  },
  47: {
    displayProperties: {
      description: '',
      name: 'Chest',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Chest',
    itemTypeRegex: 'type_armor.*',
    originBucketIdentifier: 'chest',
    grantDestinyItemType: 0,
    grantDestinySubType: 28,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [20],
    groupCategoryOnly: false,
    hash: 47,
    index: 39,
    redacted: false,
    blacklisted: false,
  },
  48: {
    displayProperties: {
      description: '',
      name: 'Legs',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Legs',
    itemTypeRegex: 'type_armor.*',
    originBucketIdentifier: 'legs',
    grantDestinyItemType: 0,
    grantDestinySubType: 29,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [20],
    groupCategoryOnly: false,
    hash: 48,
    index: 40,
    redacted: false,
    blacklisted: false,
  },
  49: {
    displayProperties: {
      description: '',
      name: 'Class Items',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Class Items',
    itemTypeRegex: 'type_armor.*',
    originBucketIdentifier: 'class',
    grantDestinyItemType: 0,
    grantDestinySubType: 30,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [20],
    groupCategoryOnly: false,
    hash: 49,
    index: 41,
    redacted: false,
    blacklisted: false,
  },
  50: {
    displayProperties: {
      description: 'Builds for your characters.',
      name: 'Subclasses',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Subclasses',
    itemTypeRegex: 'type_build.*',
    itemTypeRegexNot: 'type_build_artifact.*',
    grantDestinyItemType: 16,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [52],
    groupCategoryOnly: false,
    hash: 50,
    index: 42,
    redacted: false,
    blacklisted: false,
  },
  51: {
    displayProperties: {
      description: 'These items are not currently in use.',
      name: 'Blueprint',
      hasIcon: false,
    },
    visible: false,
    deprecated: true,
    shortTitle: 'Blueprint',
    itemTypeRegex: 'type_ship_blueprint',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 51,
    index: 43,
    redacted: false,
    blacklisted: false,
  },
  52: {
    displayProperties: {
      description: '',
      name: 'Inventory',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Inventory',
    grantDestinyItemType: 0,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [19, 41, 42, 43, 18, 40, 35, 50, 56, 58],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: true,
    hash: 52,
    index: 44,
    redacted: false,
    blacklisted: false,
  },
  53: {
    displayProperties: {
      description: 'Quests that can be completed in Destiny.',
      name: 'Quest',
      hasIcon: false,
    },
    visible: true,
    deprecated: false,
    shortTitle: 'Quest',
    itemTypeRegex: '(.*quest.*)|(questline_item_type)',
    originBucketIdentifier: '(hidden)|(temporary)',
    grantDestinyItemType: 15,
    grantDestinySubType: 0,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 53,
    index: 45,
    redacted: false,
    blacklisted: false,
  },
  54: {
    displayProperties: {
      description: 'Make sure the pointy end faces toward your enemy.',
      name: 'Sword',
      hasIcon: false,
    },
    visible: true,
    deprecated: false,
    shortTitle: 'Sword',
    itemTypeRegex: 'type_weapon_sword',
    grantDestinyItemType: 0,
    grantDestinySubType: 18,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 54,
    index: 46,
    redacted: false,
    blacklisted: false,
  },
  55: {
    displayProperties: {
      description: '(Insert appropriate Jim Carrey quote here)',
      name: 'Mask',
      hasIcon: false,
    },
    visible: false,
    deprecated: false,
    shortTitle: 'Mask',
    itemTypeRegex: 'type_armor_mask.*',
    grantDestinyItemType: 0,
    grantDestinySubType: 19,
    grantDestinyClass: 3,
    groupedCategoryHashes: [],
    isPlug: false,
    parentCategoryHashes: [],
    groupCategoryOnly: false,
    hash: 55,
    index: 47,
    redacted: false,
    blacklisted: false,
  },
};
