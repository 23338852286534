import React from 'react';
import './AjaxLoader.scss';

const ajaxLoader = () => (
  <div className="mainLoaderContainer" title="Performing Data call on Guardian.Services">
    <span className="loaderSpan span1" />
    <span className="loaderSpan span2" />
    <span className="loaderSpan span3" />
    <span className="loaderSpan span4" />
    <span className="loaderSpan span5" />
    <span className="loaderSpan span6" />
    <span className="loaderSpan span7" />
    <span className="loaderSpan span8" />
    <span className="loaderSpan span9" />
  </div>
);

export default ajaxLoader;
