// import { showNotification } from '../../../notifications/notifications';
import { CategoryMapData } from 'app/custom-data-handler/data/CategoryMapData';
import NotificationTexts from 'app/custom-data-handler/data/notificationText';
import { notify } from 'app/custom-data-handler/functions';
import {
  CategoryMapDataSimpleObject,
  OfflineListingProps,
  OfflineListingState,
  OfflineManifestAxiosResponse,
} from 'app/custom-data-handler/types';
import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2';
import { get, set } from 'idb-keyval';
import React from 'react';
import OfflineItem from './OfflineItem';
import './OfflineListing.scss';
import OfflineLoader from './OfflineLoader';

export default class OfflineListing extends React.Component<
  OfflineListingProps,
  OfflineListingState
> {
  constructor(Props: OfflineListingProps) {
    super(Props);
    this.state = {
      items: [],
      filter: [],
      needRerender: false,
      isLoaded: false,
      getRequestSent: false,
      selectedType: -1,
      manifestLoadedPercent: 0,
      itemDisplayedCount: 50,
    };
  }

  printItems = async () => {
    try {
      const trackerData: {
        [key: number]: DestinyInventoryItemDefinition;
      } = await get('offline-data');
      if (trackerData === undefined) {
        this._getManifestFromServer();
        return false;
      }
      const itemData = this.getItemHTML(trackerData);
      if (itemData) {
        this.setState({
          items: itemData,
          filter: itemData,
          isLoaded: true,
          manifestLoadedPercent: 100,
          needRerender: true,
        });
      } else {
        notify(NotificationTexts.offlineLoadingFailed);
      }
    } catch (_) {
      notify(NotificationTexts.offlineLoadingFailed);
      this.setState({
        needRerender: false,
      });
    }
  };

  getItemHTML = (itemData: { [key: number]: DestinyInventoryItemDefinition }) => {
    const returnValue: DestinyInventoryItemDefinition[] = [];
    for (const itemHash in itemData) {
      returnValue.push(itemData[itemHash]);
    }
    if (returnValue.length <= 0) {
      return false;
    }
    return returnValue;
  };

  filterData = (filterRarity: string, filterName: string, filterType: string) => {
    const filteredItems: DestinyInventoryItemDefinition[] = [];
    this.state.items.forEach((element) => {
      if (
        element.displayProperties.name !== 'Classified' &&
        element.displayProperties.name !== ''
      ) {
        const type = element.itemTypeDisplayName.toLowerCase();
        const categories = element.itemCategoryHashes;
        let rarity = element.inventory?.tierTypeName || 'Basic';
        rarity = rarity.toLowerCase();
        const name = element.displayProperties.name.toLowerCase();
        if (
          type.includes(filterType) &&
          rarity.includes(filterRarity) &&
          name.includes(filterName) &&
          rarity.charAt(rarity.indexOf(filterRarity) - 1) !== 'n' &&
          (this.state.selectedType === -1 || categories?.includes(this.state.selectedType))
        ) {
          filteredItems.push(element);
        }
      }
    });
    this.setState({
      filter: filteredItems,
      itemDisplayedCount: 50,
      needRerender: true,
    });
  };

  filterDataByName = (filterName: string) => {
    const filteredItems: DestinyInventoryItemDefinition[] = [];
    this.state.items.forEach((element) => {
      if (
        element.displayProperties.name !== 'Classified' &&
        element.displayProperties.name !== ''
      ) {
        const categories = element.itemCategoryHashes;
        const name = element.displayProperties.name.toLowerCase();
        if (
          name.includes(filterName) &&
          (this.state.selectedType === -1 || categories?.includes(this.state.selectedType))
        ) {
          filteredItems.push(element);
        }
      }
    });
    this.setState({
      filter: filteredItems,
      itemDisplayedCount: 50,
      needRerender: true,
    });
  };

  _handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement> | undefined) => {
    if (e?.keyCode === 13) {
      this._handleSearchClick();
    }
  };

  _handleSearchClick = () => {
    const SearchElement: HTMLInputElement | null = document.getElementById(
      'offlineItemSearchTextFieldId'
    ) as HTMLInputElement;
    if (SearchElement === null) {
      return false;
    }
    const searchString: string = SearchElement.value;
    let is = '';
    let name = '';
    let type = '';
    const strignArray = searchString.split(',');
    strignArray.forEach((element) => {
      const newArray = element.split(':');
      if (newArray.length === 2 && newArray[0] === 'is') {
        is = newArray[1];
      }
      if (newArray.length === 2 && newArray[0] === 'name') {
        name = newArray[1];
      }
      if (newArray.length === 2 && newArray[0] === 'type') {
        type = newArray[1];
      }
    });
    if (is === '' && name === '' && type === '') {
      this.filterDataByName(searchString);
      return false;
    }
    this.filterData(is.toLowerCase().trim(), name.toLowerCase().trim(), type.toLowerCase().trim());
  };

  _handleClearClick = () => {
    const SearchElement: HTMLInputElement | null = document.getElementById(
      'offlineItemSearchTextFieldId'
    ) as HTMLInputElement;
    if (SearchElement === null) {
      return false;
    }
    SearchElement.value = '';
    if (this.state.selectedType === -1) {
      this.setState((prevState) => ({
        filter: prevState.items,
        needRerender: true,
      }));
      return false;
    }
    this.setState((prevState) => {
      const filteredItems = prevState.items.filter((element) => {
        if (
          element.displayProperties.name === 'Classified' ||
          element.displayProperties.name === ''
        ) {
          return false;
        }
        const categories = element.itemCategoryHashes;
        return categories?.includes(prevState.selectedType);
      });
      return {
        filter: filteredItems,
        needRerender: true,
      };
    });
  };

  _getManifestFromServer = async () => {
    this.setState({
      getRequestSent: true,
    });
    const timeout = setTimeout(() => {
      notify(NotificationTexts.offlineTakingLongTime);
    }, 480000);
    const response: OfflineManifestAxiosResponse =
      await this.props.ajaxCallInstance.requestGetManifest((progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        this.setState({ manifestLoadedPercent: Number(percentCompleted.toFixed(2)) });
      });
    clearTimeout(timeout);

    if (response.error) {
      notify({
        type: 'error',
        title: 'Offline Manifest',
        message: response.message,
      });
      return;
    }
    const itemData = this.getItemHTML(response.data?.data || []);
    const test: any = false;
    if (itemData && false === test) {
      set('offline-data', itemData);
      this.setState({
        items: itemData,
        filter: itemData,
        isLoaded: true,
        manifestLoadedPercent: 100,
        needRerender: true,
        getRequestSent: false,
      });
    }
  };

  _saveManifestOnServer = async () => {
    const response = await this.props.ajaxCallInstance.requestPostManifest({
      key: $DIM_WEB_API_KEY,
    });
    if (response.error) {
      notify({
        type: 'error',
        title: 'Offline Manifest',
        message: response.message,
      });
      return;
    }
    if (response.errorCode !== 14) {
      notify({
        type: response.error ? 'error' : 'success',
        title: 'Offline Manifest',
        message: response.message,
      });
    }
    if (!response.error && response.errorCode !== 14) {
      if (!this.state.getRequestSent) {
        notify(NotificationTexts.gettingUpdatedManifest);
        this._getManifestFromServer();
      } else {
        notify(NotificationTexts.reloadIfNeeded);
      }
    }
  };

  _printCategoryBadges = () => {
    const badgeDataArray = this._getCatergoryNameasArray();
    return badgeDataArray.map((element, key) => (
      <button
        type="button"
        className="badgeButton"
        value={element.hash}
        data-name={element.name}
        data-index={element.index}
        key={key}
        data-parent={element.parent}
        data-group={element.group}
        onClick={this._handleBadgeClick}
      >
        {element.name}
      </button>
    ));
  };

  _getCatergoryNameasArray = () => {
    const returnData: CategoryMapDataSimpleObject[] = [];
    for (const key in CategoryMapData) {
      const dataObject: CategoryMapDataSimpleObject = {
        name: CategoryMapData[key].displayProperties.name,
        hash: CategoryMapData[key].hash,
        index: CategoryMapData[key].index,
        parent: CategoryMapData[key].parentCategoryHashes,
        group: CategoryMapData[key].groupedCategoryHashes,
      };
      returnData.push(dataObject);
    }
    return returnData;
  };

  _handleBadgeClick = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    const currentTarget = e?.target as HTMLButtonElement;
    const value = parseInt(currentTarget.value, 10);
    if (isNaN(value)) {
      return false;
    }
    const SearchElement: HTMLInputElement | null = document.getElementById(
      'offlineItemSearchTextFieldId'
    ) as HTMLInputElement;
    if (SearchElement === null) {
      return false;
    }
    SearchElement.value = '';
    if (value === this.state.selectedType) {
      return false;
    }
    const buttonElement = document.querySelectorAll('.badgeButton.select');
    for (const element of buttonElement) {
      element.classList.remove('select');
    }
    currentTarget.className += ' select';
    if (value === -1) {
      this.setState((prevState) => ({
        filter: prevState.items,
        needRerender: true,
        selectedType: -1,
        itemDisplayedCount: 50,
      }));
      const SearchElement: HTMLInputElement | null = document.getElementById(
        'offlineItemSearchTextFieldId'
      ) as HTMLInputElement;
      if (SearchElement === null) {
        return false;
      }
      SearchElement.value = '';
      return false;
    }
    const newFilteredItems = this._getItemByTypeOnly(value);
    this.setState({
      filter: newFilteredItems,
      needRerender: true,
      selectedType: value,
      itemDisplayedCount: 50,
    });
  };

  _getItemByTypeOnly = (type: number) =>
    this.state.items.filter((element) => {
      if (element.displayProperties.name === 'Classified') {
        return false;
      }
      return element.itemCategoryHashes?.includes(type);
    });

  componentDidMount() {
    if (!this.state.isLoaded) {
      this._saveManifestOnServer();
      this.printItems();
    }
  }

  shouldComponentUpdate(_nextProps: OfflineListingProps, nextStates: OfflineListingState) {
    if (nextStates.needRerender) {
      this.setState({
        needRerender: false,
      });
      return true;
    }
    return false;
  }

  handleLoadMore = () => {
    this.setState((prevState) => ({
      itemDisplayedCount: prevState.itemDisplayedCount + 25,
      needRerender: true,
    }));
  };

  render() {
    const badgesHTML = (
      <div className="badgeContainer">
        <button
          type="button"
          className="badgeButton select"
          value="-1"
          onClick={this._handleBadgeClick}
        >
          All
        </button>
        {this._printCategoryBadges()}
      </div>
    );

    const searchBarHTML = (
      <div className="searchBar">
        <input
          type="search"
          id="offlineItemSearchTextFieldId"
          onKeyUp={this._handleKeyUp}
          placeholder="is:{Rarity Type},name:{String},type:{Type}"
        />
        <button type="button" onClick={this._handleSearchClick}>
          Search
        </button>
        <button type="button" className="clear" onClick={this._handleClearClick}>
          Clear
        </button>
      </div>
    );

    const collectionItemHTML = (
      <div className="collectiveItems">
        {this.state.filter.slice(0, this.state.itemDisplayedCount).map((element, index) => (
          <OfflineItem item={element} key={index} />
        ))}
        {this.state.filter.length > this.state.itemDisplayedCount ? (
          <button type="button" className="offline_loadmore_button" onClick={this.handleLoadMore}>
            Load more
          </button>
        ) : null}
      </div>
    );

    const mainRenderHtml = (
      <div className="offline-data-main-container">
        <div className="filters-container">
          {searchBarHTML}
          {badgesHTML}
        </div>
        {collectionItemHTML}
      </div>
    );

    return (
      <div className={`offlineModeMainDiv`}>
        <h1>Offline Data</h1>
        {!this.state.isLoaded ? (
          <OfflineLoader progress={this.state.manifestLoadedPercent} />
        ) : (
          mainRenderHtml
        )}
      </div>
    );
  }
}
